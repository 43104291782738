import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({active}) => (
  <div className='header'>
    <div className='header-name'><p>Jon Long</p></div>
    <div className='header-nav'>
      <Link to='/' className={`header-nav-item ${(active==0) ? "active" : ""}`}>projects</Link>
      <Link to='/about' className={`header-nav-item ${(active==1) ? "active" : ""}`}>about me</Link>
      <Link to='/contact' className={`header-nav-item ${(active==2) ? "active" : ""}`}>contact</Link>
    </div>
  </div>
)

export default Header